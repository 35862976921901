import { HttpErrorResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';

@Injectable({
  providedIn: 'root',
})
export class AlertService {
  private readonly defaultDuration = 3000;

  constructor(private matSnackBar: MatSnackBar) {}

  success(message: string, duration?: number): void {
    this.matSnackBar.open(message, undefined, {
      verticalPosition: 'top',
      panelClass: 'kg-alert--success',
      duration: duration || this.defaultDuration,
    });
  }

  error(message: string, duration?: number): void {
    this.matSnackBar.open(message, undefined, {
      verticalPosition: 'top',
      panelClass: 'kg-alert--error',
      duration: duration || this.defaultDuration,
    });
  }

  /**
   * Smart error - tries to get error from backend response,
   * with fallback to HttpErrorResponse.message if impossible,
   * converting an array of errors to string if necessary.
   *
   * @param error HttpErrorResponse instance to get error from
   * @param field Object field of error.error where the message is expected to be found
   *              (as a string or string[])
   * @param duration Duration
   */
  smartError(error: HttpErrorResponse, field: string, duration = 5000): void {
    const rawMessage = error.error?.[field];
    if (rawMessage == null) {
      this.error(error.message, duration);
    } else {
      const messages = Array.isArray(rawMessage) ? rawMessage : [rawMessage];
      const message = messages
        .map(msg => msg == null ? '' : ('' + msg))
        .map(msg => msg === '' ? 'Unknown error' : '')
        .join('\n');
      this.error(message || 'Unknown error', duration);
    }
  }
}
