/* eslint-disable @typescript-eslint/naming-convention */
import { ENVIRONMENT } from '../../../../../environments/environment';

const apiUrlWS = new URL(ENVIRONMENT.wssUrl + 'ws/', new URL('/', document.location.href));
if (apiUrlWS.protocol !== 'ws:' && apiUrlWS.protocol !== 'wss:') {
  apiUrlWS.protocol = document.location.protocol === 'http:' ? 'ws:' : 'wss:';
}
const apiUrl = ENVIRONMENT.apiUrl;

if (!ENVIRONMENT.production && ENVIRONMENT.envName === 'DEVELOPMENT') {
  // eslint-disable-next-line no-console
  console.info(`\nUsing env = ${ENVIRONMENT.envName} with
  HTTPS API URL = ${ENVIRONMENT.apiUrl}
  WebSocket URL = ${apiUrlWS}\n\n`);
}


/**
 * Device(s)
 */
export const apiUrlDeviceShortList = `${apiUrl}device-short-list/`;
export const apiUrlDevices = `${apiUrl}devices/`;
export const apiUrlDevice = (deviceId: string) => `${apiUrlDevices}${deviceId}/`;
export const apiUrlDeviceReport = (deviceId: string) => `${apiUrlDevices}${deviceId}/report/`;
export const apiUrlDeviceTimelines = (deviceId: string) => `${apiUrlDevices}${deviceId}/timelines/`;
export const apiUrlDevicePieCharts = (deviceId: string) => `${apiUrlDevices}${deviceId}/pie_charts/`;

/**
 * DeviceConfiguration
 */
export const apiUrlDeviceConfiguration = `${apiUrl}device-configuration/`;
export const apiUrlDeviceServiceDates = (deviceId: string) => `${apiUrlDeviceConfiguration}${deviceId}/service_dates/`;


/**
 * Statuses
 */
export const apiUrlStatuses = (
  deviceId: string | null,
) => `${apiUrl}${deviceId ? 'devices/' + deviceId + '/' : ''}statuses/`;
export const apiUrlStatusesOverall = `${apiUrl}overall-statuses/`;



/**
 * Operation log
 */

export const apiUrlOperationLog =  (deviceId: string) => `${apiUrlDevices}${deviceId}/kws_operationlog/`;



/**
 * Errors
 */
export const apiUrlErrors = `${apiUrl}errors/`;
export const apiUrlErrorsKawasaki = `${apiUrl}kws-errors/`;

export const apiUrlErrorsTopFanuc = (deviceId: string) => `${apiUrl}devices/${deviceId}/fnc-errors/summary`;
export const apiUrlErrorsTopSourceFanuc = (deviceId: string) => `${apiUrlErrorsTopFanuc(deviceId)}/joints`;
export const apiUrlErrorsFanucAggregated = (deviceId: string) => `${apiUrlErrorsTopFanuc(deviceId)}/aggregatewindow`;

export const apiUrlErrorsTop = `${apiUrl}errors/top_errors/`;
export const apiUrlErrorsSource = `${apiUrl}errors/top_errors_source/`;
export const apiUrlEnergyConsumption = `${apiUrl}energy-consumption/`;

export const apiUrlErrorFilter = (deviceId: string) => `${apiUrlDevices}${deviceId}/fnc-logs-filter/`;


/**
 * Available views
 */
export const apiUrlAvailableViews = (deviceId: string) => `${apiUrlDevices}${deviceId}/available_views/`;


/**
 * Service packs
 */
export const apiUrlServicePacksGenerate = (deviceId: string) => `${apiUrlDevices}${deviceId}/service_packs/`;
export const apiUrlServicePacksGetResource = (
  deviceId: string,
  servicePackId: string,
) => `${apiUrlDevices}${deviceId}/service_packs/${servicePackId}`;


/**
 * Component config
 */
export const apiUrlViewComponentConfig = `${apiUrl}view-component-config/`;


/**
 * User config
 */
export const apiUrlUserConfigs = `${apiUrl}user-config/`;
export const apiUrlUserConfig = (configId: string) => `${apiUrl}user-config/${configId}/`;


/**
 * Measurements V2
 */
export const apiUrlChartMultiData = `${apiUrl}measurements/multi_param_chart/`;
export const apiUrlChartAggregations = `${apiUrl}measurements/aggregations/`;
export const apiUrlAllParameters = (deviceId: string) => `${apiUrlDevices}${deviceId}/parameters/?all_available=true`;


/**
 * Measurements
 */
export const apiUrlMeasurements = `${apiUrl}measurements/chart/`;


/**
 * Logs
 */
export const apiUrlLogs = `${apiUrl}log/`;
export const apiUrlLogsFilters = `${apiUrl}log/filters/`;

export const apiUrlLogsUr = (deviceId: string) => `${apiUrlDevices}${deviceId}/logs/`;
export const apiUrlFRLogsUr = (deviceId: string) => `${apiUrlDevices}${deviceId}/fr_logs/`;
export const apiUrlLogsUrCategories = (deviceId: string) => `${apiUrlDevices}${deviceId}/logs/message_categories/`;

export const apiUrlLogsKawasaki = (deviceId: string) => `${apiUrlDevices}${deviceId}/kws_logs/`;
export const apiUrlLogsKawasakiCategories = (deviceId: string) => `${apiUrlDevices}${deviceId}/kws_logs/message_categories/`;

export const apiUrlLogsFanuc = (deviceId: string) => `${apiUrlDevices}${deviceId}/fnc-logs/`;
export const apiUrlFanucPrograms = (deviceId: string) => `${apiUrlLogsFanuc(deviceId)}programname/`;
export const apiUrlLogsFanucCategories = (deviceId: string) => `${apiUrlDevices}${deviceId}/fnc-logs/message_categories/`;


/**
 * Alarms
 */
export const apiUrlAlarms = `${apiUrl}alarms/`;
export const apiUrlAlarmsEvent = `${apiUrl}alarm-event/`;
export const apiUrlAlarmLogs = `${apiUrl}alarm-log/`;
export const apiUrlAlarmLogsFilters = `${apiUrl}alarm-log/filters/`;
export const apiUrlAlarmActive = (alarmId: number) => `${apiUrlAlarms}${alarmId}/active/`;
export const apiUrlAlarmNotificationEmail = (alarmId: number) => `${apiUrlAlarms}${alarmId}/notification_email/`;
export const apiUrlAlarmNotificationSms = (alarmId: number) => `${apiUrlAlarms}${alarmId}/notification_sms/`;
export const apiUrlAlarmDuration = (alarmId: number) => `${apiUrlAlarms}${alarmId}/duration/`;
export const apiUrlAlarmNumberOfOccurrences = (alarmId: number) => `${apiUrlAlarms}${alarmId}/min_number_of_occurrences/`;
export const apiUrlAlarmNumberErrorScoreThreshold = (alarmId: number) => `${apiUrlAlarms}${alarmId}/error_score_threshold/`;


/**
 * Counters
 */
export const apiUrlCounters = (deviceId: string) => `${apiUrl}${deviceId}/counters/`;
export const apiUrlCountersYaskawa = (deviceId: string) =>`${apiUrl}${deviceId}/counters_yaskawa/`;


/**
 * System filters
 */
export const apiUrlSystemFilters = (deviceId: string) => `${apiUrlDevices}${deviceId}/system_filters/`;
export const apiUrlProgramNames = (deviceId: string) => `${apiUrlDevices}${deviceId}/program_names/`;


/**
 * KPIs
 */
export const apiUrlKPI = (deviceId: string) => `${apiUrlDevices}${deviceId}/kpi/`;
export const apiUrlScanfilKPI = (deviceId: string) => `${apiUrlDevices}${deviceId}/scanfilkpi/`;
export const apiUrlKassowKPI = (deviceId: string) => `${apiUrlDevices}${deviceId}/kassow_kpi/`;
export const apiUrlBMOptikKPI = (deviceId: string) => `${apiUrlDevices}${deviceId}/bm_optik_kpi/`;
export const apiUrlHutchinsonKPI = (deviceId: string) => `${apiUrlDevices}${deviceId}/hutchinson_kpi/`;
export const apiUrlHutchinsonToolsKPI = (deviceId: string) => `${apiUrlDevices}${deviceId}/hutchinson_tools_kpi/`;
export const apiUrlBmoKpiExportCSV = (deviceId: string) => `${apiUrlDevices}${deviceId}/bm_optik_kpi_csv_export/`;


/**
 * Palletisation
 */
export const apiUrlPalletisationData = (deviceId: string) => `${apiUrlDevices}${deviceId}/iecpalletisation/`;
export const apiUrlPalletisationUnileverData = (deviceId: string) => `${apiUrlDevices}${deviceId}/unileverpalletisation/`;
export const apiUrlIECPalletisationData = (deviceId: string) => `${apiUrlDevices}${deviceId}/iecpalletisation/`;
export const apiUrlHMI = `${apiUrl}last-measurements/`;


/**
 * OEE
 */
export const apiUrlOEE = (deviceId: string) => `${apiUrlDevices}${deviceId}/oee/`;


/**
 * Device configuration
 */
export const apiUrlDeviceConfigShifts = (deviceId: string) => `${apiUrl}device-configuration/${deviceId}/shifts/`;
export const apiUrlAlarmNotifications = (deviceId: string) => `${apiUrl}device-configuration/${deviceId}/alarm_notifications/`;
export const apiUrlProductionPrograms = (deviceId: string) => `${apiUrl}device-configuration/${deviceId}/fanuc_production_programs_config/`;


/**
 * Diagnostics
 */
export const apiUrlDiagnostics = (deviceId: string) => `${apiUrlDevices}${deviceId}/hardware_monitoring/`;


/**
 * Profile
 */
export const apiUrlLogin = `${apiUrl}login/`;
export const apiUrlLogout = `${apiUrl}logout/`;
export const apiUrlChangePassword = `${apiUrl}change-password/`;
export const apiUrlResetPassword = `${apiUrl}reset-password/`;


/**
 * Websocket
 */
export const apiUrlWSParameters = (
  token: string,
) => {
  const url = new URL(apiUrlWS);
  url.searchParams.set('token', token);
  return url.toString();
};


/**
 * Flight Records
 */
export const apiUrlFlightRecords = (deviceId: string) => `${apiUrlDevices}${deviceId}/flight-reports/`;
export const apiUrlFlightRecord = (deviceId: string, flightRecordId: string) =>
  `${apiUrlDevices}${deviceId}/flight-reports/${flightRecordId}/`;
export const apiUrlFlightRecordCharts = (deviceId: string, flightRecordId: string) =>
  `${apiUrlDevices}${deviceId}/flight-reports/${flightRecordId}/chart/`;
export const apiUrlFlightRecordProgramConfig = (deviceId: string, flightRecordId: string) =>
  `${apiUrlDevices}${deviceId}/flight-reports/${flightRecordId}/config_settings/`;
export const apiUrlFlightRecordProgramErrors = (deviceId: string, flightRecordId: string) =>
  `${apiUrlDevices}${deviceId}/flight-reports/${flightRecordId}/program_insights_errors/`;
export const apiUrlFlightRecordProgramReport = (deviceId: string, flightRecordId: string) =>
  `${apiUrlDevices}${deviceId}/flight-reports/${flightRecordId}/program_insights_report/`;
export const apiUrlFlightRecordProgramDetails = (deviceId: string, flightRecordId: string) =>
  `${apiUrlDevices}${deviceId}/flight-reports/${flightRecordId}/program_analysis/`;


/**
 * Score
 */
export const apiUrlScore = (deviceId: string) => `${apiUrlDevices}${deviceId}/score/`;
export const apiUrlScoreChart = (deviceId: string) => `${apiUrlDevices}${deviceId}/score/chart/`;


/**
 * Lifetime
 */
export const apiUrlLifetime = (deviceId: string) => `${apiUrlDevices}${deviceId}/lifetime/`;


/**
 * Program Backup
 */
export const apiUrlBackupCreate = (deviceId: string) => `${apiUrl}backups/list/${deviceId}/manual-backup/`;
export const apiUrlBackupDelete = (backupId: string) => `${apiUrl}backups/backup/${backupId}/delete-manual/`;
export const apiUrlBackupsList = (deviceId: string) => `${apiUrl}backups/list/${deviceId}/`;
export const apiUrlBackupsListSlim = (deviceId: string) => `${apiUrl}backups/list/${deviceId}/slim/`;
export const apiUrlBackupFileList = (backupId: string) => `${apiUrl}backups/backup/${backupId}/files/`;
export const apiUrlBackupContent = (backupId: string) => `${apiUrl}backups/content/${backupId}/`;
export const apiUrlBackupFileContent = (fileId: string) => `${apiUrl}backups/backup_file/${fileId}/content/`;
export const apiUrlBackupChangeName = (backupId: string) => `${apiUrl}backups/backup/${backupId}/set-name/`;
export const apiUrlBackupFavouritesList = (deviceId: string) => `${apiUrl}backups/list-favourites/${deviceId}/`;
export const apiUrlBackupChangeFavourite = (backupId: string) => `${apiUrl}backups/backup/${backupId}/mark-favourite/`;
