import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatNativeDateModule } from '@angular/material/core';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatIconModule } from '@angular/material/icon';
import { RouterModule } from '@angular/router';
import { NgScrollbarModule } from 'ngx-scrollbar';
import { HintModule } from './components/hint/hint.module';
import { LogsFilterCategoryComponent } from './components/logs-filter-category/logs-filter-category.component';
import { TableBasicComponent } from './components/table-basic/table-basic.component';
import { CalendarModule } from './components/time-select/components/calendar/calendar.module';
import { TimeSelectComponent } from './components/time-select/time-select.component';
import { InfiniteScrollDirective } from './directives/infinite-scroll/infinite-scroll.directive';
import { ChartCalendarHeatmapComponent } from './modules/chart/components/chart-calendar-heatmap/chart-calendar-heatmap.component';
import { ChartTimelineComponent } from './modules/chart/components/chart-timeline/chart-timeline.component';
import { FormModule } from './modules/form/form.module';
import { TimeFormatModule } from './pipes/time-format/time-format.module';
import { PreviousCurrentShiftService } from './services/previous-current-shift/previous-current-shift.service';
import { UrlsService } from './services/urls.service';

/**
 * LEGACY MODULE. Should never be used. Import what you need in you module and nth more.
 */
@NgModule({
  declarations: [
    TimeSelectComponent,
    InfiniteScrollDirective,
    ChartTimelineComponent,
    TableBasicComponent,
    ChartCalendarHeatmapComponent,
    LogsFilterCategoryComponent,
  ],
  imports: [
    CommonModule,
    RouterModule,
    FormsModule,
    ReactiveFormsModule,
    MatDatepickerModule,
    MatNativeDateModule,
    MatIconModule,
    HintModule,
    TimeFormatModule,
    CalendarModule,
    NgScrollbarModule,
    FormModule,
  ],
  exports: [
    CommonModule,
    InfiniteScrollDirective,
    ChartCalendarHeatmapComponent,
    ChartTimelineComponent,
    TableBasicComponent,
    TimeSelectComponent,
    LogsFilterCategoryComponent,
  ],
  providers: [
    UrlsService,
    PreviousCurrentShiftService,
  ],
})
export class SharedModule {}
