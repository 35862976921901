import { Pipe, PipeTransform } from '@angular/core';

// @TODO upgrade (put types and limit to string)
@Pipe({
  name: 'filter',
})
export class FilterPipe implements PipeTransform {
  transform<T extends string>(values: T[], filterWord?: string | null | undefined): T[];
  transform<T extends object>(values: T[], filterWord: string | null | undefined, key: string): T[];
  transform<T extends string | object>(values: T[], filterWord?: string, key?: string): T[] {
    let newValue: T[] = values;
    if (Array.isArray(values) && filterWord && filterWord.length > 0) {
      if (key) {
        newValue = values.filter((obj) => {
          const filteredWord = obj[key].toLowerCase();
          return filteredWord.includes(filterWord.toLowerCase());
        });
      } else {
        newValue = values.filter((value) => {
          const filteredWord = (value as string).toLowerCase();
          return filteredWord.includes(filterWord.toLowerCase());
        });
      }
    }
    return newValue;
  }
}
