import { Injectable } from '@angular/core';
import { createStore } from '@ngneat/elf';
import { selectEntities, selectEntity, upsertEntities, withEntities } from '@ngneat/elf-entities';
import { concatMap, Observable, of, tap } from 'rxjs';
import { ApiResourceShiftsService } from 'src/app/shared/modules/api/services/api-resource-shifts/api-resource-shifts.service';
import { DictionaryInterface } from '../../shared/interfaces/dictionary.interface';
import { ShiftInterface } from '../../shared/interfaces/shift.interface';

interface ShiftsStateInterface {
  deviceId: string;
  shifts: ShiftInterface[];
}

const store = createStore(
  {
    name : 'shifts',
  },
  withEntities<ShiftsStateInterface, 'deviceId'>(
    {
      idKey: 'deviceId',
    },
  ),
);

@Injectable({
  providedIn: 'root',
})
export class ShiftsStore {
  readonly state$: Observable<DictionaryInterface<ShiftsStateInterface>> = store.pipe(
    selectEntities(),
  );

  constructor(
    private apiResourceShiftsService: ApiResourceShiftsService,
  ) {}

  updateShifts(deviceId: string, shifts: ShiftInterface[]): void {
    store.update(upsertEntities({
      deviceId,
      shifts,
    }));
  }

  getShifts$(deviceId: string): Observable<ShiftInterface[]> {
    return store.pipe(
      selectEntity(deviceId),
      concatMap((state: ShiftsStateInterface) => {
        if (state) {
          return of(state.shifts);
        } else {
          return this.apiResourceShiftsService.getShifts$(deviceId).pipe(
            tap((shifts: ShiftInterface[]) => {
              this.updateShifts(deviceId, shifts);
            }),
          );
        }
      }),
    );
  }
}
