import { HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { DateRangeInterface } from '../../../interfaces/date-range.interface';

@Injectable({
  providedIn: 'root',
})
export class ApiParamsService {
  applyLimitOffset(limit: number, offset: number, params?: HttpParams): HttpParams {
    params = params || new HttpParams();
    params = params.set('limit', limit);
    params = params.set('offset', offset);
    return params;
  }

  applyDateRange(
    params: HttpParams,
    dateRange: DateRangeInterface,
  ): HttpParams {
    params = params.set('start', dateRange.dateFrom.toISOString());
    params = params.set('end', dateRange.dateTo.toISOString());

    return params;
  }

  applyDeviceId(
    params: HttpParams,
    deviceId: string,
  ): HttpParams {
    params = params.set('device', deviceId);

    return params;
  }

  applyLimit(
    params: HttpParams,
    limit: number = 50,
  ): HttpParams {
    params = params.set('limit', limit);

    return params;
  }

  applyOffset(
    params: HttpParams,
    offset: number,
  ): HttpParams {
    params = params.set('offset', offset);

    return params;
  }

  getISODateTime(date: Date): string {
    return date.toISOString();
  }

  removeEmptyValues(params: HttpParams): HttpParams {
    params.keys().forEach((paramKey: string) => {
      const paramValue: string | null = params.get(paramKey);

      if (paramValue === '' || paramValue === 'null' ||  paramValue === 'undefined') {
        params = params.delete(paramKey);
      }
    });

    return params;
  }

  applyMessageCategory(params: HttpParams, message_category: number[]): HttpParams {
    params = params.set('message_category', message_category.join(','));
    return params;
  }

  applyErrorCode(params: HttpParams, error_code: string): HttpParams {
    params = params.set('error_code', error_code);
    return params;
  }

  applyErrorCodes(params: HttpParams, error_codes: string[]): HttpParams {
    params = params.set('error_codes', error_codes.join(','));
    return params;
  }

  applyJoints(params: HttpParams, joints: number[]): HttpParams {
    params = params.set('joints', joints.join(','));
    return params;
  }

  applyCurrentPrograms(params: HttpParams, currentPrograms: string[]): HttpParams {
    params = params.set('current_programs', currentPrograms.join(','));
    return params;
  }

  applyProductionPrograms(params: HttpParams, productionPrograms: string[]): HttpParams {
    params = params.set('production_programs', productionPrograms.join(','));
    return params;
  }

  applyAggregationType(params: HttpParams, aggregationType: string) {
    params = params.set('production_programs', aggregationType);
    return params;
  }
}
