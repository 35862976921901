import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { MatIconModule } from '@angular/material/icon';
import { ClickOutsideModule } from '../../directives/click-outside/click-outside.module';
import { SanitizeHtmlModule } from './../../pipes/sanitize-html/sanitize-html.module';
import { HintComponent } from './hint.component';

@NgModule({
  declarations: [
    HintComponent,
  ],
  imports: [
    MatIconModule,
    CommonModule,
    ClickOutsideModule,
    SanitizeHtmlModule,
  ],
  exports: [
    HintComponent,
  ],
})
export class HintModule {}
