import { ChangeDetectionStrategy, ChangeDetectorRef, Component, ElementRef, HostListener, Input, ViewChild } from '@angular/core';
import placement from 'placement.js';
import { timer } from 'rxjs';
import { first } from 'rxjs/operators';
import { BREAKPOINTS } from '../../constants/breakpoints.constant';
import type { TooltipPositionType } from '../../types/tooltip-position.type';

@Component({
  selector: 'app-hint',
  templateUrl: './hint.component.html',
  styleUrls: ['./hint.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class HintComponent {
  @ViewChild('hint') hintRef: ElementRef | undefined;
  @ViewChild('hintContent') hintContentRef: ElementRef;
  @ViewChild('hintIcon') hintIconRef: ElementRef | undefined;

  @Input('description') set descriptionSetter(description: string | null) {
    this.description = description;
  }
  @Input() position: TooltipPositionType = 'bottom';

  description: string | null = null;
  isVisible = false;

  private readonly BREAKPOINT = BREAKPOINTS.DESKTOP_BREAKPOINT;

  constructor(
    private changeDetectorRef: ChangeDetectorRef,
  ) {}

  @HostListener('mouseover', ['$event']) onMouseOver(): void {
    this.showHint();
  }

  @HostListener('mouseleave', ['$event']) onMouseLeave(): void {
    this.hideHint();
  }

  showMobileTooltip($event): void {
    $event.stopPropagation();
    if (window.innerWidth <= this.BREAKPOINT) {
      this.showHint();
    }
  }

  hideMobileTooltip(): void {
    if (window.innerWidth <= this.BREAKPOINT) {
      this.hideHint();
    }
  }

  private showHint(): void {
    this.isVisible = true;
    this.changeDetectorRef.detectChanges();

    const hintPosition: TooltipPositionType = window.innerWidth <= this.BREAKPOINT ? 'bottom-end' : this.position;

    timer(0).pipe(first()).subscribe(() => {
      if (this.hintRef) {
        placement(this.hintRef.nativeElement, this.hintContentRef.nativeElement, {
          placement: hintPosition,
        });
      }
    });
  }

  private hideHint(): void {
    this.isVisible = false;
    this.changeDetectorRef.detectChanges();
  }
}
