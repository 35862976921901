import { CommonModule, DecimalPipe } from '@angular/common';
import { HTTP_INTERCEPTORS, HttpClientModule } from '@angular/common/http';
import { APP_INITIALIZER, ErrorHandler, NgModule, Provider } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { MAT_DIALOG_DEFAULT_OPTIONS, MatDialogConfig } from '@angular/material/dialog';
import { MAT_FORM_FIELD_DEFAULT_OPTIONS } from '@angular/material/form-field';
import { MatSnackBarModule } from '@angular/material/snack-bar';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { Router } from '@angular/router';
import { TraceService } from '@sentry/angular-ivy';
import { NG_SCROLLBAR_OPTIONS } from 'ngx-scrollbar';
import { ENVIRONMENT } from '../environments/environment';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { AuthInterceptor } from './core/interceptors/auth.interceptor';
import { GlobalErrorHandlerService } from './core/services/global-error-handler.service';
import { AdminOverallModule } from './modules/admin/admin-overall/admin-overall.module';
import { AccountRedirectPageModule } from './pages/account-page/account-redirect-page/account-redirect-page.module';

const sentryProviders: Provider[] =
  ENVIRONMENT.sentryDsn ? [
    {
      provide: TraceService,
      deps: [Router],
    },
    {
      provide: APP_INITIALIZER,
      useFactory: () => () => {},
      deps: [TraceService],
      multi: true,
    },
  ] : [];

@NgModule({
  declarations: [
    AppComponent,
  ],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    CommonModule,
    HttpClientModule,
    FormsModule,
    MatSnackBarModule, // used by global error.interceptor
    AppRoutingModule,
    AdminOverallModule,
    MatSnackBarModule,
    AccountRedirectPageModule,
  ],
  providers: [
    DecimalPipe, // @TODO initialize on module feature level
    {
      provide: 'googleTagManagerId',
      useValue: ENVIRONMENT.googleTagManagerId,
    },
    {
      provide: MAT_DIALOG_DEFAULT_OPTIONS,
      useValue: {
        ...new MatDialogConfig(),
        maxHeight: '70vh',
      } as MatDialogConfig,
    },
    {
      provide: MAT_FORM_FIELD_DEFAULT_OPTIONS,
      useValue: {
        appearance: 'fill',
      },
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: AuthInterceptor,
      multi: true,
    },
    {
      provide: NG_SCROLLBAR_OPTIONS,
      useValue: {
        visibility: 'always',
        trackClickScrollDuration: 0,
      },
    },
    {
      provide: ErrorHandler,
      useClass: GlobalErrorHandlerService,
    },
    ...sentryProviders,
  ],
  bootstrap: [AppComponent],
})
export class AppModule {}
