import { Component } from '@angular/core';
import { RouterModule } from '@angular/router';
import { FooterModule } from './footer/footer.module';
import { HeaderModule } from './header/header.module';

/**
 * Container for router-outlet
 */

@Component({
  standalone: true,
  selector: 'app-layout',
  templateUrl: './layout.component.html',
  styleUrls: ['./layout.component.scss'],
  imports: [
    HeaderModule,
    FooterModule,
    RouterModule,
  ],
})
export class LayoutComponent {
}
