import { Injectable } from '@angular/core';
import { DictionaryInterface } from '../../interfaces/dictionary.interface';

@Injectable({
  providedIn: 'root',
})
export class DataTransformService {
  transformArrayToDict<T>(array: T[], idKey: string = 'id'): DictionaryInterface<T> {
    return Object.fromEntries(array.map((entry: T) => [entry[idKey], entry]));
  }

  transformDictToArray<T>(dict: DictionaryInterface<T>): T[] {
    return Object.values(dict);
  };
}
