import { RobotManufacturerNameEnum } from '../enums/robot-type.enum';
import { DeviceShortInterface } from '../interfaces/device.interface';

/**
 * @description Previous implementation has been declaring it as an injectable class,
 *              however it was not doing any real use of `this` variable, therefore
 *              unnecessarily requiring injecting the service everywhere it was used.
 *              I've changed it to a simple object, de facto equivalent to a class
 *              with all methods being static, and never instantiated.
 */
// eslint-disable-next-line @typescript-eslint/naming-convention
export const RobotStatusService = {
  isUR(device: DeviceShortInterface): boolean  {
    return device.tpe.manufacturer.name === RobotManufacturerNameEnum.UR;
  },

  isYaskawa(device: DeviceShortInterface): boolean {
    return device.tpe.manufacturer.name === RobotManufacturerNameEnum.YASKAWA;
  },

  isEpson(device: DeviceShortInterface): boolean {
    return device.tpe.manufacturer.name === RobotManufacturerNameEnum.EPSON;
  },

  isTechman(device: DeviceShortInterface): boolean {
    return device.tpe.manufacturer.name === RobotManufacturerNameEnum.TECHMAN;
  },

  isKassow(device: DeviceShortInterface): boolean {
    return device.tpe.manufacturer.name === RobotManufacturerNameEnum.KASSOW;
  },

  isKuka(device: DeviceShortInterface): boolean {
    return device.tpe.manufacturer.name === RobotManufacturerNameEnum.KUKA;
  },

  isKawasaki(device: DeviceShortInterface): boolean {
    return device.tpe.manufacturer.name === RobotManufacturerNameEnum.KAWASAKI;
  },

  isFanuc(device: DeviceShortInterface): boolean {
    return device.tpe.manufacturer.name === RobotManufacturerNameEnum.FANUC;
  },

  isRobotOffline(statusOverall: string): boolean {
    return statusOverall === 'OFF';
  },

  getManufacturer(device: DeviceShortInterface): RobotManufacturerNameEnum | undefined {
    if (RobotStatusService.isUR(device)) { return RobotManufacturerNameEnum.UR; }
    else if (RobotStatusService.isYaskawa(device)) { return RobotManufacturerNameEnum.YASKAWA; }
    else if (RobotStatusService.isEpson(device)) { return RobotManufacturerNameEnum.EPSON; }
    else if (RobotStatusService.isTechman(device)) { return RobotManufacturerNameEnum.TECHMAN; }
    else if (RobotStatusService.isKassow(device)) { return RobotManufacturerNameEnum.KASSOW; }
    else if (RobotStatusService.isKuka(device)) { return RobotManufacturerNameEnum.KUKA; }
    else if (RobotStatusService.isKawasaki(device)) { return RobotManufacturerNameEnum.KAWASAKI; }
    else if (RobotStatusService.isFanuc(device)) { return RobotManufacturerNameEnum.FANUC; }
  },

  getStatusOverallIcon(statusOverall: string): string {
    switch (statusOverall) {
      case 'OK':
        return 'check_circle_outline';
      case 'WARNING':
        return 'report_problem';
      case 'ERROR':
        return 'stop_circle';
      case 'OFF':
        return 'wifi_off';
      default:
        console.error('Unknown status passed', statusOverall);
        return '';
    }
  },
};
