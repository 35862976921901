import { Component, OnInit } from '@angular/core';
import { HttpErrorResponse } from '@angular/common/http';
import { AlertService } from '../../../../shared/services/alert.service';
import { AdminOverallPasswordService } from '../admin-overall-password/admin-overall-password.service';

@Component({
  selector: 'app-admin-overall-modal',
  templateUrl: './admin-overall-modal.component.html',
  styleUrls: ['./admin-overall-modal.component.scss'],
})
export class AdminOverallModalComponent implements OnInit {
  activeTabIndex = 0;
  isSaveAvailable = false;

  constructor(
    private adminOverallPasswordService: AdminOverallPasswordService,
    private alertService: AlertService,
  ) {}

  ngOnInit(): void {}

  save(): void {
    this.isSaveAvailable = false;

    switch (this.activeTabIndex) {
      case 0:
        this.adminOverallPasswordService.savePassword$().subscribe(() => {
          this.alertService.success('Saved successfully');
          this.isSaveAvailable = true;
        }, (err: HttpErrorResponse) => {
          this.alertService.error((Object.values(err.error)[0] as string[]).join(' '), 10000);
          this.isSaveAvailable = true;
        });
        break;
      case 1:
      // tbc
        break;
      default:
        console.error('Admin overall modal tab not found.');
    }
  }

  tabIndexChange(index: number): void {
    this.activeTabIndex = index;
  }

  passwordValid(valid: boolean): void {
    this.isSaveAvailable = this.activeTabIndex === 0 && valid;
  }
}


