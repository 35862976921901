import { Component } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { FooterModalContactComponent } from './components/footer-modals/footer-modal-contact/footer-modal-contact.component';
import {
  FooterModalEuropeanUnionComponent,
} from './components/footer-modals/footer-modal-european-union/footer-modal-european-union.component';
import {
  FooterModalPrivacyPolicyComponent,
} from './components/footer-modals/footer-modal-privacy-policy/footer-modal-privacy-policy.component';

@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.scss'],
})
export class FooterComponent {
  currentYear = new Date().getFullYear();

  constructor(public dialog: MatDialog) {
  }

  openContactDialog(e: MouseEvent): void {
    e.preventDefault();
    this.dialog.open(FooterModalContactComponent);
  }

  openPrivacyPolicyDialog(e: MouseEvent): void {
    e.preventDefault();
    this.dialog.open(FooterModalPrivacyPolicyComponent);
  }

  openEuropeanFundsDialog(e: MouseEvent): void {
    e.preventDefault();
    this.dialog.open(FooterModalEuropeanUnionComponent);
  }
}
