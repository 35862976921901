import { Injectable } from '@angular/core';
import { Dayjs } from 'dayjs/esm';
import { findLast } from 'lodash-es';
import { Observable, map } from 'rxjs';
import { ShiftsStore } from '../../../store/shifts/shifts.store';
import { PreviousCurrentShiftInterface } from '../../interfaces/previous-current-shift.interface';
import { ShiftViewInterface } from '../../interfaces/shift-view.interface';
import { ShiftInterface } from '../../interfaces/shift.interface';
import { DayjsShift } from './dayjs-shift.class';

interface PreviousShiftInterface {
  shiftNumber: number;
  startDate: Dayjs;
  endDate: Dayjs;
}

interface PreviousShiftsInterface {
  previous?: PreviousShiftInterface;
  preprevious?: PreviousShiftInterface;
}

@Injectable()
export class PreviousCurrentShiftService {
  private readonly shiftPrePreviousLabel = 'Pre-previous shift';
  private readonly shiftPreviousLabel = 'Previous shift';
  private readonly shiftCurrentLabel = 'Current shift';

  constructor(
    private shiftsStore: ShiftsStore,
  ) {}

  public getShifts$(deviceId: string): Observable<PreviousCurrentShiftInterface> {
    return this.shiftsStore.getShifts$(deviceId).pipe(
      map((shifts: ShiftInterface[]) => this.getShifts(shifts)),
    );
  }

  private getShifts(shifts: ShiftInterface[]): PreviousCurrentShiftInterface {
    if (!Array.isArray(shifts) || shifts.length < 1) {
      return {
        preprevious: null,
        previous: null,
        current: null,
      };
    }
    const shiftsDayjs = shifts.map(shift => new DayjsShift(shift));
    const current = this.getCurrentShift(shiftsDayjs) ?? null;
    const { previous, preprevious } = this.getPreviousShift(shiftsDayjs, current);
    return {
      preprevious: preprevious ? {
        shiftNumber: preprevious.shiftNumber,
        shiftName: this.shiftPrePreviousLabel,
        start: preprevious.startDate.toDate(),
        end: preprevious.endDate.toDate(),
      } : null,
      previous: previous ? {
        shiftNumber: previous.shiftNumber,
        shiftName: this.shiftPreviousLabel,
        start: previous.startDate.toDate(),
        end: previous.endDate.toDate(),
      } : null,
      current,
    };
  }

  private getPreviousShift(
    shifts: readonly DayjsShift[],
    current: ShiftViewInterface | null,
  ): PreviousShiftsInterface {
    if (shifts.length === 1) {
      if (current) {
        return {};
      }

      const isStartTwoDaysAgo =
        this.isEndTheNextDay(shifts[0]) &&
        this.isStartThePreviousDay(shifts[0]) &&
        this.isEndThePreviousDay(shifts[0]);

      const startDate = this.getStartDateTime(
        shifts[0],
        this.isStartThePreviousDay(shifts[0]),
        isStartTwoDaysAgo,
      );
      const endDate = this.getEndDateTime(
        shifts[0],
        this.isEndThePreviousDay(shifts[0]),
      );

      return { previous: { shiftNumber: 1, startDate, endDate } };
    }

    if (current) {
      const currentShiftIdx = current?.shiftNumber as number  - 1;
      const previousShiftIdx = (currentShiftIdx + shifts.length - 1) % shifts.length;
      const prepreviousShiftIdx = (currentShiftIdx + shifts.length - 2) % shifts.length;
      const previousShift = shifts[previousShiftIdx];
      const prepreviousShift = shifts[prepreviousShiftIdx];
      // TODO

      const previous = {
        shiftNumber: previousShiftIdx + 1,
        startDate: this.getStartDateTime(previousShift, this.isStartThePreviousDay(previousShift)),
        endDate: this.getEndDateTime(previousShift, this.isEndThePreviousDay(previousShift)),
      };
      if (shifts.length < 3) {
        return { previous };
      }

      const preprevious = {
        shiftNumber: prepreviousShiftIdx + 1,
        startDate: this.getStartDateTime(prepreviousShift, this.isStartThePreviousDay(prepreviousShift)),
        endDate: this.getEndDateTime(prepreviousShift, this.isEndThePreviousDay(prepreviousShift)),
      };
      return { previous, preprevious };
    }

    const previousShiftEndTheSameDay: DayjsShift | undefined = findLast(
      shifts, s => s.endDate.isBefore() && s.startDate.isBefore(s.endDate));
    const previousShiftEndThePreviousDay: DayjsShift | undefined = findLast(
      shifts, s => s.startDate.isAfter());
    // TODO: calculate pre-previous if there is no current

    const findShiftNumber = (rel: ShiftInterface) => shifts.findIndex(
      (shift: ShiftInterface) => shift.start === rel.start && shift.end === rel.end,
    ) + 1;

    if (previousShiftEndTheSameDay) {
      const isStartThePreviousDay = this.isEndTheNextDay(previousShiftEndTheSameDay);
      return { previous: {
        shiftNumber: findShiftNumber(previousShiftEndTheSameDay),
        startDate: this.getStartDateTime(previousShiftEndTheSameDay, isStartThePreviousDay),
        endDate: previousShiftEndTheSameDay.endDate,
      } };
    } else if (previousShiftEndThePreviousDay) {
      return { previous: {
        shiftNumber: findShiftNumber(previousShiftEndThePreviousDay),
        startDate: previousShiftEndThePreviousDay.startDate.subtract(1, 'day'),
        endDate: previousShiftEndThePreviousDay.endDate.subtract(1, 'day'),
      } };
    }

    console.error('Cannot determine prev and curr shift', shifts);
    return {};
  }

  private getCurrentShift(shifts: DayjsShift[]): ShiftViewInterface | undefined {
    for (let idx = 0; idx < shifts.length; ++idx) {
      let { startDate, endDate } = shifts[idx];
      const isEndTheNextDay = !startDate.isBefore(endDate);
      if (isEndTheNextDay) {
        endDate = endDate.add(1, 'day');
      }

      // ignore if not overlapping
      if (startDate.isAfter() || endDate.isBefore()) {
        continue;
      }

      return {
        shiftNumber: idx + 1,
        shiftName: this.shiftCurrentLabel,
        start: startDate.toDate(),
        end: endDate.toDate(),
      };
    }
  }

  private getStartDateTime(
    shift: DayjsShift,
    isPrevDay: boolean,
    isTwoDaysAgo: boolean = false,
  ): Dayjs {
    if (isTwoDaysAgo) {
      return shift.startDate.subtract(2, 'day');
    } else if (isPrevDay) {
      return shift.startDate.subtract(1, 'day');
    } else {
      return shift.startDate;
    }
  }

  private getEndDateTime(
    shift: DayjsShift,
    isPrevDay: boolean,
    isNextDay: boolean = false,
  ): Dayjs {
    if (isPrevDay) {
      return shift.endDate.subtract(1, 'day');
    } else if (isNextDay) {
      return shift.endDate.add(1, 'day');
    } else {
      return shift.endDate;
    }
  }

  private isEndTheNextDay(shift: DayjsShift): boolean {
    return !shift.startDate.isBefore(shift.endDate);
  }

  private isStartThePreviousDay(shift: DayjsShift): boolean {
    return shift.startDate.isAfter();
  }

  private isEndThePreviousDay(shift: DayjsShift): boolean {
    return shift.endDate.isAfter();
  }
}
