import { Injectable } from '@angular/core';
import { createStore } from '@ngneat/elf';
import {
  withEntities,
  upsertEntities,
  selectEntity,
  selectEntities,
} from '@ngneat/elf-entities';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { ViewInterface } from '../../shared/interfaces/view.interface';
import { DictionaryInterface } from '../../shared/interfaces/dictionary.interface';

export interface AvailableViewsStateInterface {
  deviceId: string;
  availableViews: ViewInterface[];
}

const store = createStore(
  { name: 'availableViews' },
  withEntities<AvailableViewsStateInterface, 'deviceId'>({
    idKey: 'deviceId',
  }),
);

@Injectable({ providedIn: 'root' })
export class AvailableViewsStore {
  readonly state$: Observable<DictionaryInterface<AvailableViewsStateInterface>> = store.pipe(
    selectEntities(),
  );

  updateAvailableViews(
    deviceId: string,
    availableViews: ViewInterface[],
  ): void {
    store.update(
      upsertEntities({
        deviceId,
        availableViews,
      }),
    );
  }

  getAvailableViews$(deviceId: string): Observable<ViewInterface[] | undefined> {
    return store.pipe(
      selectEntity(deviceId),
      map((state: AvailableViewsStateInterface) => {
        return state?.availableViews;
      }),
    );
  }

  getAvailableViews(deviceId: string): ViewInterface[] | undefined {
    return store.getValue().entities[deviceId]?.availableViews;
  }
}
