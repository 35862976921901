import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { ReactiveFormsModule } from '@angular/forms';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';
import { HintModule } from '../../components/hint/hint.module';
import { FormCheckboxComponent } from './components/form-checkbox/form-checkbox.component';
import { FormFeedbackComponent } from './components/form-feedback/form-feedback.component';
import { FormInputComponent } from './components/form-input/form-input.component';
import { FormLabelComponent } from './components/form-label/form-label.component';
import { FormSearchComponent } from './components/form-search/form-search.component';
import { FormSwitchComponent } from './components/form-switch/form-switch.component';

@NgModule({
  declarations: [
    FormCheckboxComponent,
    FormFeedbackComponent,
    FormInputComponent,
    FormLabelComponent,
    FormSearchComponent,
    FormSwitchComponent,
  ],
  exports: [
    FormCheckboxComponent,
    FormFeedbackComponent,
    FormInputComponent,
    FormLabelComponent,
    FormSearchComponent,
    FormSwitchComponent,
  ],
  imports: [
    CommonModule,
    HintModule,
    MatCheckboxModule,
    MatInputModule,
    MatFormFieldModule,
    MatSlideToggleModule,
    ReactiveFormsModule,
  ],
})
export class FormModule {}
