export enum RobotManufacturerNameEnum {
  UR = 'Universal Robots',
  TECHMAN = 'TM/Omron',
  YASKAWA = 'Yaskawa',
  EPSON = 'Epson',
  KASSOW = 'Kassow',
  KUKA = 'KUKA',
  KAWASAKI = 'Kawasaki',
  FANUC = 'Fanuc',
}
