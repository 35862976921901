<div
  #hint
  class="hint"
  [ngClass]="{
    'hint--visible': isVisible
  }"
  appClickOutside
  (click)="showMobileTooltip($event)"
  (clickOutside)="hideMobileTooltip()"
  *ngIf="description"
>
  <div
    #hintIcon
    class="hint-icon"
  >
    <img src="/assets/gfx/icon__question--circle.svg" alt="" />
  </div>
  <div
    #hintContent
    class="hint-content"
  >
    <div class="hint-content-inner" [innerHTML]="description | sanitizeHtml"></div>
  </div>
</div>
