import { ChangeDetectionStrategy, ChangeDetectorRef, Component, ElementRef, HostListener, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { Event, NavigationEnd, Router } from '@angular/router';
import { isEqual } from 'lodash-es';
import { Observable, Subject, Subscription, combineLatest, of } from 'rxjs';
import { debounceTime, filter, switchMap, takeUntil } from 'rxjs/operators';
import { routingPathBase, routingPathLogin } from '../../../shared/constants/routing-path.constant';
import { DeviceInterface } from '../../../shared/interfaces/device.interface';
import { DictionaryInterface } from '../../../shared/interfaces/dictionary.interface';
import { ViewInterface } from '../../../shared/interfaces/view.interface';
import { ApiResourceAccountService } from '../../../shared/modules/api/services/api-resource-account/api-resource-account.service';
import { UrlsService } from '../../../shared/services/urls.service';
import { UserSessionService } from '../../../shared/services/user-session.service';
import { AvailableViewsStore } from '../../../store/available-views/available-views.store';
import { CurrentViewStore } from '../../../store/current-view/current.view.store';
import { DevicesStore } from '../../../store/devices/devices.store';
import { AdminOverallModalComponent } from '../../admin/admin-overall/admin-overall-modal/admin-overall-modal.component';

/* Keep it in sync with _variables.scss */
const breakpoints = {
  // xs: 480,
  // sm: 576,
  // md: 768,
  lg: 1024,
  xl: 1200,
  // xxl: 1560,
  xxxl: 1700,
};

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class HeaderComponent implements OnInit, OnDestroy {
  @ViewChild('menu') menuRef: ElementRef;

  availableViews?: ViewInterface[];
  devices: DictionaryInterface<DeviceInterface>;
  device?: DeviceInterface;
  routingPathBase: string;
  username = '';
  isUserToolOpen = false;
  isHamburgerOpen = false;
  private width: number;
  private deviceSubscription: Subscription;

  private readonly destroyed$: Subject<void> = new Subject<void>();

  constructor(
    private router: Router,
    private changeDetectorRef: ChangeDetectorRef,
    private availableViewsStore: AvailableViewsStore,
    private devicesStore: DevicesStore,
    private urlsService: UrlsService,
    private userSession: UserSessionService,
    private apiResourceAccountService: ApiResourceAccountService,
    private currentViewStore: CurrentViewStore,
    private rootElementRef: ElementRef,
    public dialog: MatDialog,
  ) {
    rootElementRef.nativeElement.classList.add('empty');
    this.deviceSubscription = combineLatest([
      this.deviceChanges$(),
      this.devicesStore.devices$.pipe(
        // Do not trigger change detection if devices are equal according to deep comparison
        filter(devices => !isEqual(this.devices, devices)),
      ),
      this.availableViewsStore.state$,
    ]).pipe(debounceTime(200)).subscribe(([deviceId, devices, availableViews]) => {
      this.devices = devices;
      this.device = deviceId ? devices[deviceId] : undefined;
      this.availableViews = deviceId ? availableViews[deviceId].availableViews : undefined;
      this.changeDetectorRef.detectChanges();
      setTimeout(() => this.updateClasses());
    });
  }

  @HostListener('window:resize', ['$event'])
  onResize($event: { target: Window }): void {
    this.updateClasses($event.target.innerWidth);
  }

  ngOnInit(): void {
    this.routingPathBase = routingPathBase;
    this.updateClasses(window.innerWidth);
    this.changeDetectorRef.detectChanges();
  }

  ngOnDestroy(): void {
    this.deviceSubscription.unsubscribe();
    this.destroyed$.next();
    this.destroyed$.complete();
  }

  onLogout(): void {
    this.apiResourceAccountService.logout$().subscribe({
      next: () => {
        this.userSession.removeToken();
        this.router.navigateByUrl(routingPathLogin);
      },
      error: () => {
        this.userSession.removeToken();
        this.router.navigateByUrl(routingPathLogin);
      },
    });
  }

  storeCurrentView($event: boolean, currentView: ViewInterface): void {
    if ($event && this.device) {
      this.currentViewStore.updateCurrentView(currentView);
    }
  }

  openAdminModal(): void {
    this.dialog.open(AdminOverallModalComponent, {
      width: '768px',
      maxWidth: '90vw',
      height: '640px',
      maxHeight: '90vh',
    });
    this.changeDetectorRef.detectChanges();
  }

  private deviceChanges$(): Observable<string | null> {
    return this.router.events.pipe(
      filter((event: Event) => {
        return event instanceof NavigationEnd;
      }),
      switchMap((event: NavigationEnd) => {
        const isHomePage: boolean = event.url === '/';
        const newDeviceId: string | null = isHomePage ? null :
          this.urlsService.getUUIDFromUrl(event.url) as string | null;

        return of(newDeviceId);
      }),
      takeUntil(this.destroyed$),
    );
  }

  private updateClasses(width?: number): void {
    if (Number.isFinite(width)) {
      this.width = width as number;
    }
    const root: HTMLDivElement = this.rootElementRef.nativeElement;

    const setClasses = () => {
      Object.keys(classes).forEach(cls => {
        root.classList.toggle(cls, classes[cls]);
      });
    };

    const empty = !this.availableViews;
    const hamburger = !empty && this.width <= breakpoints.lg;
    const classes = {
      empty,
      hamburger,
      measuring: !empty && !hamburger,
      wide: false,
      narrow: false,
      narrower: false,
    };
    setClasses();

    if (classes.measuring) {
      const menu: HTMLDivElement = this.menuRef.nativeElement;
      const spacer: HTMLElement | null = root.querySelector('.spacer');
      const userTools: HTMLElement | null = root.querySelector('.user-tools');
      const container: HTMLElement | null = root.querySelector('.kgn-container');

      const headerWidth = container?.clientWidth ?? -1;
      if (headerWidth < menu.clientWidth || !spacer || !userTools) {
        classes.hamburger = true;
      } else if (spacer.clientWidth < menu.clientWidth) {
        classes.narrow = true;

        const availableWidth = headerWidth - userTools.clientWidth + 6.4;
        classes.narrower = availableWidth < menu.clientWidth;
      } else {
        classes.wide = true;
      }

      classes.measuring = false;
      setClasses();
    }
  }
}
