import { Injectable } from '@angular/core';
import { createStore } from '@ngneat/elf';
import { selectEntities, selectEntity, setEntities, upsertEntities, withEntities } from '@ngneat/elf-entities';
import { concatMap, Observable, of, map, tap } from 'rxjs';
import { DeviceInterface } from '../../shared/interfaces/device.interface';
import { DictionaryInterface } from '../../shared/interfaces/dictionary.interface';
import { DataTransformService } from '../../shared/services/data-transform/data-transform.service';
import { DevicesEffects } from './devices.effects';

const store = createStore(
  {
    name: 'devices',
  },
  withEntities<DeviceInterface>(),
);

const devicesListStore = createStore(
  {
    name: 'devicesList',
  },
  withEntities<DeviceInterface>(),
);

@Injectable({ providedIn: 'root' })
export class DevicesStore {

  readonly devices$: Observable<DictionaryInterface<DeviceInterface>> = store.pipe(
    selectEntities(),
  );

  readonly devicesList$: Observable<DictionaryInterface<DeviceInterface>> = devicesListStore.pipe(
    selectEntities(),
  );

  constructor(
    private dataTransformService: DataTransformService,
    private devicesEffects: DevicesEffects,
  ) {}

  updateDevice(_deviceId: string, device: DeviceInterface): void {
    store.update(upsertEntities(device));
  }

  updateAllDevices(devices: DeviceInterface[]): void {
    devicesListStore.update(setEntities(devices));
  }

  getDevice$(deviceId: string): Observable<DeviceInterface | undefined> {
    return store.pipe(
      selectEntity(deviceId),
    );
  }

  getAllDevices$(): Observable<DictionaryInterface<DeviceInterface>> {
    return devicesListStore.pipe(
      selectEntities(),
      concatMap((state: DictionaryInterface<DeviceInterface>) => {
        if (Object.keys(state)?.length) {
          return of(state);
        } else {
          return this.devicesEffects.getDevices$().pipe(
            tap((devices: DeviceInterface[]) => {
              this.updateAllDevices(devices);
            }),
            map((devices: DeviceInterface[]) => this.dataTransformService.transformArrayToDict(devices)),
          );
        }
      }),
    );
  }

  getDevice(deviceId: string): DeviceInterface {
    return store.getValue().entities[deviceId];
  }
}
