import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { RobotStatusOverallInterface } from '../../../../interfaces/robot-status-overall.interface';
import { RobotStatusInterface } from '../../../../interfaces/robot-status.interface';
import { apiUrlStatuses, apiUrlStatusesOverall } from '../../constants/api-url.constant';

@Injectable({
  providedIn: 'root',
})
export class ApiResourceStatusesService {
  constructor(
    private httpClientService: HttpClient,
  ) {}

  getStatuses$(deviceId: string | null): Observable<RobotStatusInterface[]> {
    return this.httpClientService.get<RobotStatusInterface[]>(apiUrlStatuses(deviceId));
  }

  getStatusesOverall$(): Observable<RobotStatusOverallInterface[]> {
    return this.httpClientService.get<RobotStatusOverallInterface[]>(apiUrlStatusesOverall);
  }
}
