import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { RobotStatusInterface } from '../../shared/interfaces/robot-status.interface';
import { ApiResourceStatusesService } from '../../shared/modules/api/services/api-resource-statuses/api-resource-statuses.service';

@Injectable({
  providedIn: 'root',
})
export class RobotStatusEffects {
  constructor(
    private apiResourceStatusesService: ApiResourceStatusesService,
  ) {}

  getStatus$(deviceId: string): Observable<RobotStatusInterface[]> {
    return this.apiResourceStatusesService.getStatuses$(deviceId);
  }
}
