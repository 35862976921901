import { Injectable } from '@angular/core';
import { map, Observable } from 'rxjs';
import { ApiResourceDevicesService } from '../../shared/modules/api/services/api-resource-devices/api-resource-devices.service';
import { DeviceInterface } from './../../shared/interfaces/device.interface';

@Injectable({
  providedIn: 'root',
})
export class DevicesEffects {
  constructor(
    private apiResourceDevicesService: ApiResourceDevicesService,
  ) {}

  getDevices$(): Observable<DeviceInterface[]> {
    return this.apiResourceDevicesService.getDevices$().pipe(
      map(device => device.results),
      map((devices: DeviceInterface[]) => {
        return devices.sort((a: DeviceInterface, b: DeviceInterface) => {
          return a.name.localeCompare(b.name);
        });
      }),
    );
  }
}

