import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { catchError, map } from 'rxjs/operators';
import { apiUrlAvailableViews } from '../../constants/api-url.constant';
import { ViewInterface } from '../../../../interfaces/view.interface';

@Injectable({
  providedIn: 'root',
})
export class ApiResourceAvailableViewsService {
  settingsView: ViewInterface = {
    display_name: 'Settings',
    base_url: '/settings/',
    mobile_version_available: true,
  };

  constructor(
    private httpClientService: HttpClient,
  ) {}

  getAvailableViews$(deviceId: string): Observable<ViewInterface[]> {
    return this.httpClientService.get<ViewInterface[]>(apiUrlAvailableViews(deviceId)).pipe(
      map((availableViews: ViewInterface[]) => ([...availableViews, this.settingsView])),
      catchError(() => {
        return of([]);
      }),
    );
  }
}
