import { NgModule } from '@angular/core';
import { MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';
import { AccountRedirectPageComponent } from './account-redirect-page.component';

@NgModule({
  declarations: [
    AccountRedirectPageComponent,
  ],
  imports: [
    MatIconModule,
    MatButtonModule,
  ],
})
export class AccountRedirectPageModule {}
