import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { ShiftInterface } from '../../../../interfaces/shift.interface';
import { apiUrlDeviceConfigShifts } from '../../constants/api-url.constant';

@Injectable({
  providedIn: 'root',
})
export class ApiResourceShiftsService {
  constructor(
    private httpClientService: HttpClient,
  ) {}

  getShifts$(deviceId: string): Observable<ShiftInterface[]> {
    return this.httpClientService.get<ShiftInterface[]>(apiUrlDeviceConfigShifts(deviceId)).pipe(
      map((res: ShiftInterface[]) => {
        return res.map((shift: ShiftInterface) => ({
          start: shift.start.substring(0, 5),
          end: shift.end.substring(0, 5),
        }));
      }),
    );
  }

  saveShifts$(deviceId: string, shifts: ShiftInterface[] ): Observable<ShiftInterface[]> {
    return this.httpClientService.put<{ shifts: ShiftInterface[] }>(apiUrlDeviceConfigShifts(deviceId), { shifts }).pipe(
      map((res: { shifts: ShiftInterface[]}) => res.shifts),
    );
  }
}
