import { Component, Input, forwardRef } from '@angular/core';
import { ControlValueAccessor, FormControl, NG_VALUE_ACCESSOR, ValidationErrors } from '@angular/forms';
import { DictionaryInterface } from './../../../../interfaces/dictionary.interface';
import { FORM_FEEDBACK_MESSAGES } from './constants/form-feedback-messages.constant';

@Component({
  selector: 'app-form-feedback',
  templateUrl: './form-feedback.component.html',
  styleUrls: ['./form-feedback.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => FormFeedbackComponent),
      multi: true,
    },
  ],
})

export class FormFeedbackComponent implements ControlValueAccessor {
  @Input() formControl: FormControl<any>;
  @Input() customErrorMessages: DictionaryInterface<string> = {};

  FORM_FEEDBACK_MESSAGES: typeof FORM_FEEDBACK_MESSAGES = FORM_FEEDBACK_MESSAGES;

  constructor() {}

  writeValue(): void {}

  registerOnChange(): void {}

  registerOnTouched(): void {}

  displayErrorMessage(error: ValidationErrors): string {
    let displayedMessage = '';

    if (FORM_FEEDBACK_MESSAGES[error.key]) {
      displayedMessage = FORM_FEEDBACK_MESSAGES[error.key];
    }

    if (this.customErrorMessages[error.key]) {
      displayedMessage = this.customErrorMessages[error.key];
    }

    return displayedMessage;
  }
}
