import { Injectable } from '@angular/core';

/**
 * Manage token and username in Local Storage
 */
@Injectable({
  providedIn: 'root',
})
export class UserSessionService {
  setUserName(userName: string) {
    localStorage.setItem('userName', userName);
  }

  getUserName(): string | null {
    return localStorage.getItem('userName');
  }

  setToken(token: string): void {
    localStorage.setItem('token', token);
  }

  getToken(): string | null {
    return localStorage.getItem('token');
  }

  removeToken(): void {
    this.removeUserName();
    localStorage.removeItem('token');
  }

  private removeUserName() {
    localStorage.removeItem('userName');
  }
}
