import { Component, Input } from '@angular/core';
import { TooltipPositionType } from '../../../../types/tooltip-position.type';

@Component({
  selector: 'app-form-label',
  template: `
    <label
      *ngIf="label.length > 0"
      [ngClass]="{ 'form-label--tooltip' : tooltip }"
    >
      {{ label }}
      <app-hint
        *ngIf="tooltip"
        [description]="tooltip"
        [position]="tooltipPosition"
        class="form-label__hint"
      ></app-hint>
    </label>
  `,
  styleUrls: ['./form-label.component.scss'],
})
export class FormLabelComponent {
  @Input() label = '';
  @Input() tooltip: string | undefined;
  @Input() tooltipPosition: TooltipPositionType = 'bottom';
}
