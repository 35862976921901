import { MatIconModule } from '@angular/material/icon';
import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { MatMenuModule } from '@angular/material/menu';
import { AdminOverallModule } from '../../admin/admin-overall/admin-overall.module';
import { DevicesDropdownComponent } from '../../../shared/components/devices-dropdown/devices-dropdown.component';
import { DeviceStatusIconComponent } from '../../../shared/components/device-status-icon/device-status-icon.component';
import { HeaderComponent } from './header.component';

@NgModule({
  declarations: [
    HeaderComponent,
  ],
  imports: [
    CommonModule,
    RouterModule,
    AdminOverallModule,
    MatIconModule,
    DevicesDropdownComponent,
    DeviceStatusIconComponent,
    MatMenuModule,
    DeviceStatusIconComponent,
    DevicesDropdownComponent,
  ],
  exports: [
    HeaderComponent,
  ],
})

export class HeaderModule {}
