import { Directive, ElementRef, EventEmitter, HostListener, Output } from '@angular/core';

@Directive({
  selector: '[appClickOutside]',
})
export class ClickOutsideDirective {
  @Output() clickOutside = new EventEmitter<void>();

  constructor(
    private elementRef: ElementRef,
  ) {}

  @HostListener('document:click', ['$event.target'])
  onClick(target: Element): void {
    const targetElement: Element = this.elementRef.nativeElement.contains(target);

    if (!targetElement) {
      this.clickOutside.emit();
    }
  }
}
