import { enableProdMode } from '@angular/core';
import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';
import { devTools } from '@ngneat/elf-devtools';
import { init, browserTracingIntegration } from '@sentry/angular-ivy';
import { AppModule } from './app/app.module';
import { ENVIRONMENT } from './environments/environment';

const setupSentry: () => void = () => {
  if (ENVIRONMENT.sentryDsn) {
    init({
      dsn: ENVIRONMENT.sentryDsn,
      integrations: [
        browserTracingIntegration({
          tracingOrigins: [ENVIRONMENT.origin],
        }),
      ],
      tracesSampleRate: ENVIRONMENT.envName === 'DEVELOPMENT' ? 1 : 0.05,
    });
  }

  if (ENVIRONMENT.production) {
    enableProdMode();
  }
};

const setupApp: () => void = () => {
  platformBrowserDynamic()
    .bootstrapModule(AppModule)
    .then(() => {
      devTools({
        maxAge: 50,
      });
    })
    .catch((err) => console.error(err));
};

setupSentry();
setupApp();
