import { ChangeDetectionStrategy, Component } from '@angular/core';
import { SUPPORT_EMAIL } from '../../../shared/constants/support-email.const';

@Component({
  selector: 'app-account-redirect-page',
  templateUrl: './account-redirect-page.component.html',
  styleUrls: ['./account-redirect-page.component.scss', '../account-page.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AccountRedirectPageComponent {
  readonly supportEmail = SUPPORT_EMAIL;

  private readonly newDomainURL = 'https://app.kogena.com';

  redirectToNewDomain(): void {
    window.location.replace(this.newDomainURL);
  }
}
