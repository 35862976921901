import { Injectable } from '@angular/core';
import { createStore } from '@ngneat/elf';
import {
  withEntities,
  upsertEntities,
  selectEntity,
  selectEntities,
} from '@ngneat/elf-entities';
import { Observable, of } from 'rxjs';
import { concatMap, tap } from 'rxjs/operators';
import { DictionaryInterface } from '../../shared/interfaces/dictionary.interface';
import { RobotStatusInterface } from '../../shared/interfaces/robot-status.interface';
import { RobotStatusEffects } from './robot-statuses.effects';

export interface RobotStatusesStateInterface {
  deviceId: string;
  robotStatuses: RobotStatusInterface[];
}

const store = createStore(
  { name: 'robotStatuses' },
  withEntities<RobotStatusesStateInterface, 'deviceId'>({
    idKey: 'deviceId',
  }),
);

@Injectable({ providedIn: 'root' })
export class RobotStatusesStore {
  readonly state$: Observable<DictionaryInterface<RobotStatusesStateInterface>> = store.pipe(
    selectEntities(),
  );

  constructor(
    private robotStatusEffects: RobotStatusEffects,
  ){}

  updateRobotStatus(
    deviceId: string,
    robotStatuses: RobotStatusInterface[],
  ): void {
    store.update(
      upsertEntities({
        deviceId,
        robotStatuses,
      }),
    );
  }

  getRobotStatus$(deviceId: string): Observable<RobotStatusInterface[]> {
    return store.pipe(
      selectEntity(deviceId),
      concatMap((state: RobotStatusesStateInterface) => {
        if (state) {
          return of(state.robotStatuses);
        } else {
          return this.robotStatusEffects.getStatus$(deviceId).pipe(
            tap((robotStatus: RobotStatusInterface[]) => {
              this.updateRobotStatus(deviceId, robotStatus);
            }),
          );
        }
      }),
    );
  }

  getRobotStatus(deviceId: string): RobotStatusInterface[] | undefined {
    return store.getValue().entities[deviceId]?.robotStatuses;
  }
}
