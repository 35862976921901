import { ChangeDetectionStrategy, Component, forwardRef, Input } from '@angular/core';
import { ControlValueAccessor, FormControl, NG_VALUE_ACCESSOR } from '@angular/forms';
import { DictionaryInterface } from '../../../../interfaces/dictionary.interface';

@Component({
  selector: 'app-form-input',
  templateUrl: './form-input.component.html',
  styleUrls: ['./form-input.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => FormInputComponent),
      multi: true,
    },
  ],
})
export class FormInputComponent implements ControlValueAccessor {
  @Input() formControl: FormControl<string | number | null>;
  @Input() label = '';
  @Input() tooltip = '';
  @Input() placeholder = '';
  @Input() type = 'text';
  @Input() required = false;
  @Input() isMinimalVersion = false;
  @Input() browserAutocomplete = '';
  @Input() customErrorMessages: DictionaryInterface<string> = {};

  registerOnChange(): void {}

  registerOnTouched(): void {}

  writeValue(): void {}

  onInput(target: EventTarget | null): void {
    if ((target as HTMLInputElement)?.value === '') {
      this.formControl.reset(null);
    }
  }
}
