import { Injectable } from '@angular/core';
import { createStore } from '@ngneat/elf';
import {
  selectEntities,
  selectEntity,
  upsertEntities,
  withEntities,
} from '@ngneat/elf-entities';
import { Observable, concatMap, of, tap } from 'rxjs';
import { ApiResourceStatusesService } from 'src/app/shared/modules/api/services/api-resource-statuses/api-resource-statuses.service';
import { DictionaryInterface } from '../../shared/interfaces/dictionary.interface';
import { RobotStatusOverallInterface } from '../../shared/interfaces/robot-status-overall.interface';

export interface RobotStatusOverallStateInterface {
  // hardcoded id === 'id' to always get the same statusesOverall and be consistent with the other stores
  id: 'id';
  robotStatusOverall: RobotStatusOverallInterface[];
}

const store = createStore(
  { name: 'robotStatusOverall' },
  withEntities<RobotStatusOverallStateInterface, 'id'>({
    idKey: 'id',
  }),
);

@Injectable({ providedIn: 'root' })
export class RobotStatusOverallStore {
  readonly state$: Observable<DictionaryInterface<RobotStatusOverallStateInterface>> = store.pipe(
    selectEntities(),
  );

  constructor(
    private apiResourceStatusesService: ApiResourceStatusesService,
  ){}

  updateRobotStatusesOverall(
    id: 'id',
    robotStatusOverall: RobotStatusOverallInterface[],
  ): void {
    store.update(
      upsertEntities({
        id,
        robotStatusOverall,
      }),
    );
  }

  getRobotStatusesOverall$(id: 'id'): Observable<RobotStatusOverallInterface[]> {
    return store.pipe(
      selectEntity(id),
      concatMap((state: RobotStatusOverallStateInterface) => {
        if (state) {
          return of(state.robotStatusOverall);
        } else {
          return this.apiResourceStatusesService.getStatusesOverall$().pipe(
            tap((robotStatusOverall: RobotStatusOverallInterface[]) => {
              this.updateRobotStatusesOverall(id, robotStatusOverall);
            }),
          );
        }
      }),
    );
  }

  getRobotStatusOverall(id: 'id'): RobotStatusOverallInterface[] | undefined {
    return store.getValue().entities[id]?.robotStatusOverall;
  }
}

