import dayjs, { Dayjs } from 'dayjs/esm';
import { ShiftInterface } from '../../interfaces/shift.interface';

export class DayjsShift implements ShiftInterface {
  start: string;
  end: string;
  startDate: Dayjs;
  endDate: Dayjs;

  constructor(shift: ShiftInterface) {
    this.start = shift.start;
    this.end = shift.end;
    const day = new Date().toISOString().split('T')[0] + 'T';
    this.startDate = dayjs(day + this.start);
    this.endDate = dayjs(day + this.end);
  }

  offset(offset: number): DayjsShift {
    const clone = new DayjsShift(this);
    clone.startDate = clone.startDate.add(offset, 'day');
    clone.endDate = clone.endDate.add(offset, 'day');
    return clone;
  }
}
