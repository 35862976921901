import { HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { DictionaryInterface } from '../../../interfaces/dictionary.interface';

@Injectable({
  providedIn: 'root',
})
export class ApiParamsSystemFiltersService {
  applySystemFilters(
    params: HttpParams,
    systemFilters: DictionaryInterface<string> | undefined,
  ): HttpParams {
    if (systemFilters) {
      Object.entries(systemFilters).forEach((systemFilterKeyValue: [string, string]) => {
        if (systemFilterKeyValue[1] !== null) {
          params = params.set(`filters[${systemFilterKeyValue[0]}]`, systemFilterKeyValue[1]);
        }
      });
    }

    return params;
  }
}
