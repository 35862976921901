import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, tap } from 'rxjs';
import { ReportsDtoInterface, TimelinesDtoInterface } from '../../../../../modules/report/models/report.model';
import { DeviceInterface, DeviceShortInterface } from '../../../../interfaces/device.interface';
import { DictionaryInterface } from '../../../../interfaces/dictionary.interface';
import { apiUrlDevice, apiUrlDeviceReport, apiUrlDeviceShortList, apiUrlDeviceTimelines, apiUrlDevices, apiUrlDevicePieCharts } from '../../constants/api-url.constant';
import { ApiPaginationInterface } from '../../interfaces/api-pagination.interface';
import { ApiParamsSystemFiltersService } from '../api-params-system-filters.service';
import { ApiParamsService } from '../api-params.service';

function fixImageUrl(device: DeviceInterface): void {
  if (device.tpe.image?.startsWith('http:') && document.location.protocol === 'https:') {
    device.tpe.image = 'https' + device.tpe.image.slice(4);
  }
}

@Injectable({
  providedIn: 'root',
})
export class ApiResourceDevicesService {
  constructor(
    private httpClientService: HttpClient,
    private apiParamsService: ApiParamsService,
    private apiParamsSystemFiltersService: ApiParamsSystemFiltersService,
  ) {}

  getDevicesShortList$(): Observable<DeviceShortInterface[]> {
    return this.httpClientService.get<DeviceShortInterface[]>(apiUrlDeviceShortList);
  }

  getDevices$(): Observable<ApiPaginationInterface<DeviceInterface[]>> {
    const params: HttpParams = new HttpParams().set('limit', 150);

    return this.httpClientService
      .get<ApiPaginationInterface<DeviceInterface[]>>(apiUrlDevices, { params })
      .pipe(tap((devices: ApiPaginationInterface<DeviceInterface[]>) => {
        devices.results.forEach(fixImageUrl);
      }));
  }

  getDevice(
    deviceId: string,
  ): Observable<DeviceInterface> {
    return this.httpClientService.get<DeviceInterface>(apiUrlDevice(deviceId)).pipe(tap(fixImageUrl));
  }

  getDeviceReport(
    deviceId: string,
    dateFrom: Date,
    dateTo: Date,
    systemFilters: DictionaryInterface<string> | undefined,
  ): Observable<ReportsDtoInterface> {
    let params: HttpParams = new HttpParams();
    params = this.apiParamsService.applyDeviceId(params, deviceId);
    params = this.apiParamsService.applyDateRange(params, {
      dateFrom,
      dateTo,
    });
    params = this.apiParamsSystemFiltersService.applySystemFilters(params, systemFilters);

    return this.httpClientService
      .get<ReportsDtoInterface>(apiUrlDeviceReport(deviceId), { params });
  }

  getDeviceTimelines(
    deviceId: string,
    dateFrom: Date,
    dateTo: Date,
    systemFilters: DictionaryInterface<string> | undefined,
    pieCharts: boolean,
  ): Observable<TimelinesDtoInterface> {
    let params: HttpParams = new HttpParams();
    params = this.apiParamsService.applyDeviceId(params, deviceId);
    params = this.apiParamsService.applyDateRange(params, {
      dateFrom,
      dateTo,
    });
    params = this.apiParamsSystemFiltersService.applySystemFilters(params, systemFilters);

    const url = pieCharts ? apiUrlDevicePieCharts(deviceId) : apiUrlDeviceTimelines(deviceId);
    return this.httpClientService.get<TimelinesDtoInterface>(url, { params });
  }

  editNamePlate(deviceId: string, plate: string): Observable<DeviceInterface> {
    return this.httpClientService.patch<DeviceInterface>(`${apiUrlDevices}${deviceId}/`, { plate });
  }

  editDeviceDescription(
    description: string,
    id: string,
  ): Observable<Pick<DeviceInterface, 'description'>> {
    return this.httpClientService.patch<Pick<DeviceInterface, 'description'>>(
      `${apiUrlDevices}${id}/`,
      { description },
    );
  }

  editDeviceSite(
    site: string,
    id: string,
  ): Observable<Pick<DeviceInterface, 'site'>> {
    return this.httpClientService.patch<Pick<DeviceInterface, 'site'>>(
      `${apiUrlDevices}${id}/`,
      { site },
    );
  }
}
