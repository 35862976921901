import { Injectable } from '@angular/core';
import dayjs from 'dayjs/esm';
import { Subject, of, switchMap, tap, timer } from 'rxjs';
import { RobotStatusOverallStore } from '../../../store/robot-status-overall/robot-status-overall.store';
import { RobotStatusesStore } from '../../../store/robot-status/robot-statuses.store';
import { RobotStatusOverallInterface } from '../../interfaces/robot-status-overall.interface';
import { RobotStatusInterface } from '../../interfaces/robot-status.interface';
import { RobotStatusService } from '../../services/robot-status.service';
import { StatusDurationInterface } from './interfaces/status-duration.interface';

@Injectable()
export class RobotStatusBadgeService {
  refreshInterval = 1000;
  robotStatus: RobotStatusInterface[];
  robotStatusesOverall: RobotStatusOverallInterface[];

  constructor(
    private robotStatusesStore: RobotStatusesStore,
    private robotStatusOverallStore: RobotStatusOverallStore,
  ){}

  autoRefreshStatus$(changedAt: string, statusId: string, deviceId: string): Subject<StatusDurationInterface> {
    const subject$: Subject<StatusDurationInterface> = new Subject();
    const observable$ = timer(0, this.refreshInterval).pipe(

      switchMap(() => this.robotStatusesStore.getRobotStatus$(deviceId)),
      tap((item: RobotStatusInterface[]) => {
        this.robotStatus = item;
      }),

      switchMap(() => this.robotStatusOverallStore.getRobotStatusesOverall$('id')),
      tap((item: RobotStatusOverallInterface[]) => {
        this.robotStatusesOverall = item;
      }),

      switchMap(() => {
        const robotStatusChangedAt: number = dayjs(changedAt).valueOf();
        const timeFromLastStatusChange: number = dayjs().valueOf() - robotStatusChangedAt;
        const lastChangedDate: string = dayjs(changedAt).format('YYYY-MM-DD HH:mm:ss');
        const robotStatus: RobotStatusInterface | undefined = this.robotStatus.find(
          (status: RobotStatusInterface) => {
            return status.id === statusId;
          },
        );

        const statusName: string | undefined = robotStatus?.name;
        let statusOverallId: string | undefined;
        let statusOverall: RobotStatusOverallInterface | undefined;
        let statusOverallName: string | undefined;
        let statusOverallIcon: string | undefined;
        let statusOverallColor: string | undefined;

        if (robotStatus) {
          statusOverallId = robotStatus.overall_status_id;
        }

        if (statusOverallId) {
          statusOverall = this.robotStatusesOverall.find(
            (status: RobotStatusOverallInterface) => {
              return status.id === statusOverallId;
            },
          );
        }

        if (statusOverall) {
          statusOverallName = statusOverall.name;
          statusOverallIcon = RobotStatusService.getStatusOverallIcon(statusOverall.name);
          statusOverallColor = statusOverall.color;
        }

        const statusData: StatusDurationInterface = {
          timeFromLastStatusChange,
          lastChangedDate,
          statusOverallName,
          statusOverallIcon,
          statusOverallColor,
          statusName,
        };
        return of(statusData);
      }),
    );

    observable$.subscribe(subject$);
    return(subject$);
  }
}
