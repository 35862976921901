import { CommonModule } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormControl, ReactiveFormsModule, Validators } from '@angular/forms';
import { MatRippleModule } from '@angular/material/core';
import { Router } from '@angular/router';
import { routingPathLogin } from '../../../shared/constants/routing-path.constant';
import { ApiResourceAccountService } from '../../../shared/modules/api/services/api-resource-account/api-resource-account.service';
import { FormModule } from '../../../shared/modules/form/form.module';
import { FormGroupGenericType } from '../../../shared/modules/form/types/form-group-generic.type';

@Component({
  standalone: true,
  selector: 'app-account-reset-password-page',
  templateUrl: './account-reset-password-page.component.html',
  styleUrls: ['../account-page.scss'],
  imports: [
    CommonModule,
    ReactiveFormsModule,
    FormModule,
    MatRippleModule,
  ],
})
export class AccountResetPasswordPageComponent implements OnInit {
  resetPasswordForm: FormGroupGenericType<{ email: string }>;
  errors: string[] = [];

  constructor(
    private fb: FormBuilder,
    private router: Router,
    private apiResourceAccountService: ApiResourceAccountService,
  ) {}

  ngOnInit(): void {
    this.resetPasswordForm = this.fb.group({
      email: ['', [Validators.required, Validators.email]],
    });
  }

  onSubmit(): void {
    if (this.resetPasswordForm.valid) {
      this.apiResourceAccountService
        .resetPassword$(this.resetPasswordForm.value)
        .subscribe({
          next: () => {
            this.router.navigateByUrl('/login');
          },
          error: (err) => {
            if (err?.status === 400) {
              if (err.error && Object.keys(err.error).length) {
                this.errors = Object.values(err.error)?.reduce(
                  (
                    initArr: string[],
                    error: string,
                  ): string[] => [...initArr, error],
                  [],
                ) as string[];
              }
            }
          },
        });
    }
  }

  goToLogin(): void {
    this.router.navigateByUrl(routingPathLogin);
  }

  get email(): FormControl {
    return this.resetPasswordForm.get('email') as FormControl;
  }
}
